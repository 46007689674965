@import "../../variables/homepage";

.header {
	height: 62px;
	margin: 40px 0;
	line-height: 62px;
	text-align: center;
	background-image: url('#{$default_dir}#{$mediaPartners-header-background-img}');
	background-repeat: repeat-x;
	background-position: center;
	background-size: contain;

	.title {
		display: inline-block;
		width: auto;
		padding: 0 20px;
		font-size: 28px;
		color: $mediaPartners-header-title-color;
		background-color: $mediaPartners-header-title-background;

		@include sf_pro_textsemibold;

		@include media-breakpoint-down(md) {
			font-size: 24px;
		}
	}
}

.in-the-media-container {
	position: relative;
	width: 100%;
	height: auto;
	text-align: center;

	.content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.view {
			position: absolute;
			top: calc(50% - 30px);
			left: 0;
			width: 100%;
			font-size: 22px;
			line-height: 40px;
			color: $white;
			text-shadow: 0 0 20px $black;
			vertical-align: middle;
			cursor: pointer;

			@include sf_pro_textlight;

			@include media-breakpoint-between(sm, md) {
				font-size: 18px;
			}

			i {
				font-size: 40px;
				vertical-align: middle;

				@include media-breakpoint-down(sm) {
					display: block;
				}
			}

			@include media-breakpoint-down(sm) {
				.text {
					display: none;
				}
			}

			&:hover {
				color: $primary-blue;
			}
		}

		img {
			margin: 0 auto;

			&.desktop {
				display: block;

				@include media-breakpoint-down(sm) {
					display: none;
				}
			}

			&.mobile {
				display: none;

				@include media-breakpoint-down(sm) {
					display: block;
				}
			}
		}
	}
}